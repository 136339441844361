/* Primary Colors */
.color-primary-dark {
  color: var(--color-primary-dark) !important;
}

.color-primary-light {
  color: var(--color-primary-light) !important;
}

.color-pink {
  color: var(--color-pink) !important;
}

.color-yellow {
  color: var(--color-yellow) !important;
}

.color-gray {
  color: var(--color-gray) !important;
}

.color-blue {
  color: var(--color-blue) !important;
}

.color-blue-light {
  color: var(--color-light-blue) !important;
}

.color-green {
  color: var(--color-green) !important;
}

.color-peach {
  color: var(--color-light-peach) !important;
}

.color-orange {
  color: var(--color-orange) !important;
}

.color-red {
  color: var(--color-red) !important;
}

.color-white {
  color: var(--color-white) !important;
}

/* Primary Fill Colors */
.fill-primary-dark {
  fill: var(--color-primary-dark) !important;
}

.fill-primary-light {
  fill: var(--color-primary-light) !important;
}

/* Accent Fill Colors */
.fill-secondary-light {
  fill: var(--color-secondary-light) !important;
}

.fill-secondary-dark {
  fill: var(--color-secondary-dark) !important;
}

.fill-pink {
  fill: var(--color-pink) !important;
}

.fill-yellow {
  fill: var(--color-yellow) !important;
}

.fill-gray {
  fill: var(--color-gray) !important;
}

.fill-blue {
  fill: var(--color-blue) !important;
}

.fill-blue-light {
  fill: var(--color-light-blue) !important;
}

.fill-green {
  fill: var(--color-green) !important;
}

.fill-orange {
  fill: var(--color-orange) !important;
}

.fill-red {
  fill: var(--color-red) !important;
}

.fill-pink {
  fill: var(--color-pink) !important;
}

.fill-pink-light {
  fill: var(--color-pink-light) !important;
}

.fill-white {
  fill: var(--color-white) !important;
}

.fill-light-gray {
  fill: var(--color-light-gray) !important;
}

.fill-light-purple {
  fill: var(--color-light-purple) !important;
}

.fill-green-100 {
  fill: var(--color-green-100) !important;
}

/* Primary Border Left Colors */
.border-left-primary-dark {
  border-left-color: var(--color-primary-dark) !important;
}

.border-left-primary-light {
  border-left-color: var(--color-primary-light) !important;
}

/* Accent Border Left Colors */
.border-left-secondary-light {
  border-left-color: var(--color-secondary-light) !important;
}

.border-left-secondary-dark {
  border-left-color: var(--color-secondary-dark) !important;
}

.border-left-pink {
  border-left-color: var(--color-pink) !important;
}

.border-left-yellow {
  border-left-color: var(--color-yellow) !important;
}

.border-left-gray {
  border-left-color: var(--color-gray) !important;
}

.border-left-blue {
  border-left-color: var(--color-blue) !important;
}

.border-left-blue-light {
  border-left-color: var(--color-light-blue) !important;
}

.border-left-green {
  border-left-color: var(--color-green) !important;
}

.border-left-orange {
  border-left-color: var(--color-orange) !important;
}

.border-left-red {
  border-left-color: var(--color-red) !important;
}

.border-left-pink-light {
  border-left-color: var(--color-pink-light) !important;
}

.border-left-white {
  border-left-color: var(--color-white) !important;
}

.border-left-light-gray {
  border-left-color: var(--color-light-gray) !important;
}

.border-left-light-purple {
  border-left-color: var(--color-light-purple) !important;
}

/* Primary Background Colors */
.bg-primary-dark {
  background-color: var(--color-primary-dark) !important;
}

.bg-primary-light {
  background-color: var(--color-primary-light) !important;
}

/* Accent Background Colors */
.bg-secondary-light {
  background-color: var(--color-secondary-light) !important;
}

.bg-secondary-dark {
  background-color: var(--color-secondary-dark) !important;
}

.bg-green-100 {
  background-color: var(--color-green-100) !important;
}

.bg-pink {
  background-color: var(--color-pink) !important;
}
.bg-peach {
  background-color: var(--color-light-peach) !important;
}
.bg-yellow {
  background-color: var(--color-yellow) !important;
}

.bg-gray {
  background-color: var(--color-gray) !important;
}

.bg-blue {
  background-color: var(--color-blue) !important;
}

.bg-icon-blue {
  background-color: var(--color-icon-bg-blue) !important;
}

.bg-blue-light {
  background-color: var(--color-light-blue) !important;
}

.bg-green {
  background-color: var(--color-green) !important;
}

.bg-orange {
  background-color: var(--color-orange) !important;
}

.bg-red {
  background-color: var(--color-red) !important;
}
.bg-pink {
  background-color: var(--color-pink) !important;
}
.bg-pink-light {
  background-color: var(--color-pink-light) !important;
}

.bg-white {
  background-color: var(--color-white) !important;
}

.bg-light-gray {
  background-color: var(--color-light-gray) !important;
}

.bg-light-purple {
  background-color: var(--color-light-purple) !important;
}

/* Primary Border Colors */
.border-primary-dark {
  border-color: var(--color-primary-dark) !important;
}

.border-primary-light {
  border-color: var(--color-primary-light) !important;
}

/* Accent Border Colors */
.border-default {
  border-color: var(--color-border) !important;
}

.border-pink {
  border-color: var(--color-pink) !important;
}

.border-yellow {
  border-color: var(--color-yellow) !important;
}

.border-gray {
  border-color: var(--color-gray) !important;
}

.border-blue {
  border-color: var(--color-blue) !important;
}

.border-green {
  border-color: var(--color-green) !important;
}

.border-orange {
  border-color: var(--color-orange) !important;
}

.border-red {
  border-color: var(--color-red) !important;
}

.border-white {
  border-color: var(--color-white) !important;
}

.icon-orange {
  color: var(--color-orange) !important;
}
.icon-green {
  color: var(--color-primary-light) !important;
}
.icon-red {
  color: var(--color-red) !important;
}
