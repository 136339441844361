.navigation-tab {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 1rem 0;
  background-color: var(--color-secondary-light);
  gap: 20px;

  .tab-item {
    text-align: center;
    padding: 1rem;
    cursor: pointer;
    background-color: var(--color-white);
    box-shadow: 1px 2px 4px 2px rgba(0, 0, 0, 0.1);
    border-left: 8px solid var(--color-border-secondary);
    transition: all 0.6s ease;
    border-radius: 5px;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tab-label {
      font-size: 13px;
      font-weight: 500;
      color: var(--color-primary-dark);
    }

    &.active {
      border-left: 8px solid var(--color-primary-light);
      background-color: var(--color-secondary-light);
    }

    &:hover {
      background-color: var(--color-white);
    }
  }
}

// Media queries for responsiveness

// For screens between 1280px and 1440px
@media (min-width: 1280px) and (max-width: 1440px) {
  .navigation-tab {
    gap: 18px;
    padding: 1rem 0rem;
    padding-right: 2px;
    display: grid;
    grid-template-columns: repeat(3, auto);
  }

  .tab-item {
    padding: 0.9rem;
    font-size: 14px;
  }
}

// Ensure wrapping on screens smaller than 1440px
@media (max-width: 1260px) {
  .navigation-tab {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 15px;
    padding: 1rem 0rem;
    padding-right: 2px;
  }
}

// For screens smaller than 1024px
@media (max-width: 1024px) {
  .navigation-tab {
    gap: 15px;
    display: grid;
    grid-template-columns: auto auto;
    padding: 1rem 0.5rem;
  }

  .tab-item {
    padding: 0.5rem;
  }
}

// For screens smaller than 768px
@media (max-width: 768px) {
  .navigation-tab {
    gap: 10px;
    padding: 1rem 0rem;
    padding-right: 2px;
  }

  .tab-item {
    padding: 0.5rem;
    font-size: 14px;
  }
}

// For screens smaller than 480px
@media (max-width: 480px) {
  .navigation-tab {
    display: flex;
    flex-direction: column; // Stack items vertically
    gap: 6.5px;
    padding: 1rem 0rem;
    padding-right: 2px;
  }

  .tab-item {
    width: 100%;
    padding: 0.5rem;
    font-size: 12px;
  }
}

// For screens with a height of 372px and width of 1600px
@media (max-width: 1600px) and (max-height: 372px) {
  .navigation-tab {
    display: flex;
    gap: 15px;
    padding: 1rem 0rem;
    padding-right: 2px;
  }

  .tab-item {
    padding: 0.4rem;
    font-size: 12px;
    box-shadow: none;
    border-radius: 3px;
  }
}
