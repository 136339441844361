.profile-picture-uploader {
  display: flex;
  flex-direction: column;
}

.file-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.client-photo-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
}

.image-container,
.add-media-container {
  position: relative;
  width: 150px !important;
  height: 150px !important;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #e0e0e0;
  cursor: pointer;
}

.client-media {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  transition: filter 0.6s ease;
}

.image-container:hover .client-media {
  filter: brightness(0.8); /* Darken the image on hover */
}

.image-container:hover .edit-icon,
.image-container:hover .remove-icon {
  background-color: #0b5d3e; /* Darken the icons on hover */
}

.edit-icon,
.remove-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: #137c4e;
  color: #ffffff;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.6s ease; /* Smooth transition for hover effect */
}

.remove-icon {
  right: 40px;
}

.edit-icon i,
.remove-icon i {
  font-size: 10px;
}

.drop-zone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 2px dashed #dce0d9;
  border-radius: 10px;
  cursor: pointer;
  transition:
    all 0.6s ease,
    background-color 0.6s ease,
    border-color 0.6s ease;

  &:hover {
    background-color: #dce0d9;
    border-color: #ffffff;

    .icon-plus {
      color: #ffffff;
    }
  }
}

.icon-plus {
  font-size: 50px;
  color: #dce0d9;
}

.description-image {
  font-size: 14px;
  color: #53605d;
  text-align: center;
  margin-bottom: 2px !important;
  // padding: 0 5px;
}
.image-subtitle {
  font-size: 8px;
}
.error-message {
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
  margin-top: 5px;
  width: 100%;
  background-color: rgba(255, 0, 0, 0.8);
  color: #fff;
  padding: 5px;
  text-align: center;
  border-radius: 3px;
  font-size: 0.9em;
}

.full-preview-media {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.edit-modal {
  top: 20vh;
}

.edit-modal,
.preview-modal {
  .modal-content {
    padding: 20px;
    border-radius: 10px;
    transition: all 0.8s ease;
  }
  transition: all 0.8s ease;
}

.label {
  font-size: 14px;
  font-weight: 500;
  color: var(--color-primary-dark);

  @media (max-width: 1200px) {
    font-size: 12px;
  }

  @media (max-width: 480px) {
    font-size: 10px;
  }
}

/* For large desktops (min-width: 1200px) */
@media (min-width: 1200px) {
  .image-container,
  .add-media-container {
    width: 150px !important;
    height: 150px !important;
  }
}

/* For desktops (between 992px and 1199px) */
@media (max-width: 1199px) {
  .image-container,
  .add-media-container {
    width: 150px !important;
    height: 150px !important;
  }
}

/* For tablets in landscape mode (between 768px and 991px) */
@media (max-width: 991px) {
  .image-container,
  .add-media-container {
    width: 150px !important;
    height: 150px !important;
  }
}

/* For tablets in portrait mode (between 600px and 767px) */
@media (max-width: 767px) {
  .image-container,
  .add-media-container {
    width: 140px !important;
    height: 140px !important;
  }
}

/* For large mobile devices (between 480px and 599px) */
@media (max-width: 599px) {
  .image-container,
  .add-media-container {
    width: 120px !important;
    height: 120px !important;
  }
}

/* For medium mobile devices (between 360px and 479px) */
@media (max-width: 479px) {
  .image-container,
  .add-media-container {
    width: 90px !important;
    height: 90px !important;
  }
}

@media (min-width: 1280px) and (max-width: 1440px) {
  .image-container,
  .add-media-container {
    width: 130px !important;
    height: 130px !important;
  }
}

/* For small mobile devices (between 320px and 359px) */
@media (max-width: 359px) {
  .image-container,
  .add-media-container {
    width: 100px !important;
    height: 100px !important;
  }
}

/* For very small devices (less than 320px) */
@media (max-width: 319px) {
  .image-container,
  .add-media-container {
    width: 90px !important;
    height: 90px !important;
  }
}
