.custom-add-new-field {
  display: flex;
  align-items: center;
  justify-content: start;
  cursor: pointer;
}

.field-icon-style {
  justify-content: center;
  align-items: center;
  color: var(--color-border-secondary);
  border: 1px solid var(--color-border-secondary);
  border-radius: 5px;
  font-size: 15px;
  width: 30px;
  height: 30px;
  padding: 5px;
}

.field-label {
  font-size: 14px;
  color: var(--color-placeholder-text);
  margin-left: 15px; /* Space between icon and text */
  padding-top: 15px;
}
