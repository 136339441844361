.select-component {
  width: 100%;

  .select-label {
    margin-bottom: 2px;
    font-size: 14px;
    color: var(--color-primary-dark);
  }

  .form-select {
    height: 35px;
    padding: 5px 8px;
    font-size: 14px;
    color: var(--color-placeholder-text);
    border: 1px solid var(--color-border);
    background-color: #ffff;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 10px;

    &:hover {
      border-color: #4caf50;
    }

    &:focus {
      border-color: #4caf50;
      box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.25);
    }

    option {
      background-color: white;
      color: var(--color-placeholder-text);

      &:hover,
      &:focus,
      &:active {
        background-color: #e8f5e9 !important;
        color: #4caf50 !important;
      }

      &:checked {
        background-color: #4caf50 !important;
        color: #ffffff !important;
      }
    }
  }

  .invalid-feedback {
    font-size: 12px;
  }

  .required-indicator {
    font-size: 12px;
  }

  .select-row {
    display: flex;
    align-items: center;

    .label-container {
      flex: 1;
    }

    .select-container {
      flex: 2;
    }
  }
  .text-sentence-capitalize:first-letter {
    text-transform: uppercase;
  }
}
