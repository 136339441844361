.navigation-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  .nav-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 60px;

    .back-btn-container {
      display: flex;
      align-items: center;
      flex-grow: 1; /* Allow it to grow to take up available space */
      max-width: 60%; /* Limit its maximum width */

      .nav-back {
        padding-top: 5px;
        align-content: center;
        height: 30px;
        width: 30px;
        text-align: left;
        vertical-align: middle;
        color: var(--color-primary-light);
        background-color: var(--color-primary-bg);
        transition:
          background-color 0.6s ease,
          color 0.6s ease;
        &:hover {
          color: var(--color-btn-primary-hover);
        }

        .back-btn {
          height: 20px;
          width: 20px;
          margin-right: 20px;
          color: var(--color-primary-light);
          cursor: pointer;
        }
      }

      .nav-title {
        font-size: 20px;
        font-weight: 500;
        color: var(--color-primary-dark);
        white-space: nowrap; /* Prevent text wrapping */
        overflow: hidden; /* Hide overflow */
        text-overflow: ellipsis; /* Add ellipsis for overflowing text */
        flex-grow: 1; /* Allow it to expand and take available space */
        max-width: 100%; /* Ensure it respects the container's constraints */
      }
    }
  }
}

/* Media Queries */

/* Large Screens (Desktops) */
@media (min-width: 1200px) {
  .nav-title {
    font-size: 24px;
  }
}

/* Medium Screens (Laptops and Tablets in Landscape) */
@media (max-width: 1024px) {
  .navigation-wrapper {
    .nav-header {
      flex-direction: row;
      .nav-title {
        font-size: 22px;
      }
    }
  }
}

/* Small Screens (Tablets in Portrait) */
@media (max-width: 768px) {
  .navigation-wrapper {
    .nav-header {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      height: 120px;
      padding-bottom: 5px;

      .back-btn-container {
        width: 100%;
        justify-content: space-between;

        .nav-back {
          height: 25px;
          width: 25px;

          .back-btn {
            height: 18px;
            width: 18px;
            margin-right: 10px;
          }
        }

        .nav-title {
          font-size: 18px;
          margin-top: 5px;
        }
      }
    }
  }
}

/* Extra Small Screens (Large Phones) */
@media (max-width: 576px) {
  .nav-title {
    font-size: 16px;
  }
}

/* Very Small Screens (Small Phones) */
@media (max-width: 480px) {
  .nav-title {
    font-size: 14px;
  }
}

/* Ultra Small Screens (Old Phones or Narrow Devices) */
@media (max-width: 320px) {
  .nav-title {
    font-size: 12px;
  }
}
