.grid-table {
  position: relative;
  height: 100%;
  width: 100%;
  font-family: "Poppins", sans-serif;
}

.ag-root-wrapper,
.ag-header,
.ag-cell {
  font-family: "Poppins", sans-serif;
}

.ag-theme-alpine,
.ag-theme-balham {
  .ag-header,
  .ag-cell {
    font-family: "Poppins", sans-serif;
  }
}

.ag-theme-alpine .ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within,
.ag-theme-alpine .ag-ltr .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
.ag-theme-alpine .ag-ltr .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group,
.ag-theme-alpine .ag-ltr .ag-cell-range-single-cell,
.ag-theme-alpine .ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle,
.ag-theme-alpine .ag-rtl .ag-cell-focus:not(.ag-cell-range-selected):focus-within,
.ag-theme-alpine .ag-rtl .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
.ag-theme-alpine .ag-rtl .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group,
.ag-theme-alpine .ag-rtl .ag-cell-range-single-cell,
.ag-theme-alpine .ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle {
  border-color: #137c4e;
  border-radius: 2px;
}

.ag-header-cell-label {
  font-weight: 500;
  color: #003528;
  font-size: 12px;
}

.ag-cell {
  font-weight: 400;
  color: #53605d;
  font-size: 14px;
}

.container-ag-grid-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}

.icon-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  transition:
    background-color 0.6s,
    box-shadow 0.6s;
  cursor: pointer;
  color: var(--color-border);
}

.icon-button i {
  font-size: 16px;
}

.eye-icon-row {
  color: #ff823c;
  background-color: #ffe0c1; /* Light background on hover */
}

.eye-icon-row:hover {
  background-color: #ffe0c1; /* Light background on hover */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.eye-icon-row:active {
  background-color: #ffd1a1; /* Darker background on active */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2) inset;
}

.edit-icon-row {
  color: #137c4e;
  background-color: #d6ede2; /* Light background on hover */
}

.edit-icon-row:hover {
  background-color: #d6ede2; /* Light background on hover */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.edit-icon-row:active {
  background-color: #b2dfc6; /* Darker background on active */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2) inset;
}

.delete-icon-row {
  color: #b61111;
  background-color: #f3d6d6;
}

.delete-icon-row:hover {
  background-color: #f3d6d6; /* Light background on hover */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.delete-icon-row:active {
  background-color: #e3b2b2; /* Darker background on active */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2) inset;
}

.icon-flip {
  transform: scaleX(-1) rotate(-15deg);
}

.kick-out-icon {
  color: var(--color-red);
}

.actions-col .icon-button {
  margin-left: 3px;
}

.actions-col .icon-button:first-child {
  margin-left: inherit;
}
