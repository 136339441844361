.sms-consent-modal {
  &-body {
    .sms-opt-in-text {
      font-size: 14px;
      line-height: 1.5;

      .highlight-link {
        color: var(--color-primary-light); // Bootstrap primary color
        text-decoration: none;
        font-weight: 600;
        transition: color 0.2s ease-in-out;

        &:hover {
          color: var(--color-primary-dark); // Bootstrap primary color
          text-decoration: underline;
        }
      }
    }
  }

  &-footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
}
