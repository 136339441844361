.parent-container {
  margin-bottom: 20px;
  .label {
    font-size: 14px;
    color: var(--color-primary-dark);
  }

  .file-upload {
    width: 100%;
    margin: 0px auto;

    .upload-area {
      width: 100%;
      height: 135px;
      border: 2px dashed #d9d9d9;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: background-color 0.6s ease;

      &.drag-over {
        background-color: #f0f8ff;
        border: 2px dashed var(--color-primary-light);
      }

      &.error {
        background-color: #f0f8ff;
        border: 2px dashed var(--color-btn-danger-active);
      }

      .upload-content {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        .upload-icon {
          font-size: 28px;
          color: var(--color-yellow);
          margin-bottom: 10px;
        }

        .drag-drop-text {
          font-size: 17px;
        }

        .from-device-text {
          font-size: 13px;

          .browse {
            color: var(--color-primary-light);
            cursor: pointer;
            font-size: 13px;
            font-weight: 700;
          }
        }
      }
    }

    .file-input {
      display: none;
    }

    .file-preview {
      margin-top: 15px;
      gap: 10px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .file-item {
        display: flex;
        max-width: 250px;
        align-items: center;
        justify-content: space-between;
        padding: 4px 8px;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        position: relative;

        .file-name {
          font-size: 14px;
          white-space: nowrap; // Prevent text from wrapping to a new line
          overflow: hidden; // Hide overflow text
          text-overflow: ellipsis; // Add ellipsis (…) for overflow text
        }

        .removes-icon {
          svg {
            color: #ff4d4f;
            cursor: pointer;
            font-size: 14px;
            border-radius: 50%;
            padding: 1px 2px;
            margin-left: 5px;

            &:hover {
              background-color: #ff4d4f;
              color: #f0f8ff;
            }
          }
        }
      }
    }
    .file-error {
      font-size: 12px;
      color: var(--color-red);
    }
  }
}
