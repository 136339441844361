/* Container for the thumbs-up/thumbs-down buttons */
.thumbs-toggle-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Custom styling for the like and dislike buttons */

.like-button {
  padding-right: 10px;
}

.like-button,
.dislike-button {
  cursor: pointer;
  transition: all 0.3s ease;
}

.like-button:hover,
.dislike-button:hover {
  transform: scale(1.1); /* Slightly scale up on hover */
}

/* Like button active state */
.like-active .like-icon {
  color: #137c4e; /* Green when liked */
  transform: scale(1.1); /* Scale up the icon when active */
}

/* Dislike button active state */
.dislike-active .dislike-icon {
  color: #b61111; /* Red when disliked */
  transform: scale(1.1); /* Scale up the icon when active */
}

/* Default icon colors when not active */
.like-icon.unliked {
  color: #cccccc; /* Grey when not liked */
}

.dislike-icon.undisliked {
  color: #cccccc; /* Grey when not disliked */
}
