.label-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}
.labeled-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px; // Limit input width for better UX on large screens
  margin-bottom: 5px; // Space between inputs in a form
  position: relative;
  justify-content: center;
  transition: all 0.6s;

  &__label {
    font-size: 12px;
    font-weight: bold;
    color: var(--color-green);
    margin-bottom: 0.5rem;
  }

  &__input {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    background-color: var(--color-background-primary);
    border: none;
    border-bottom: 2px solid var(--color-border-primary);
    outline: none;
    transition: border-color 0.6s;
    color: var(--color-primary-dark);
    font-weight: 400;

    &:focus {
      border-bottom: 2px solid var(--color-primary-dark);
    }

    &:disabled {
      background-color: var(--color-background-secondary);
      cursor: not-allowed;
    }

    &--error {
      border-bottom: 2px solid var(--color-red);
    }
  }
  &__toggle-visibility {
    position: absolute;
    right: 5px;
    // top: 12px;
    font-size: 12px;
    color: #555;
    transition: color 0.6s;
    padding: 2.5px;
    display: flex;
    cursor: pointer;
    background-color: var(--color-background-secondary);
    z-index: 100000;
    &:hover {
      color: var(--color-primary-dark);
    }
  }

  &__error {
    font-size: 12px;
    color: var(--color-red);
    transition: all 0.6s;
  }
}

/* Media Queries for Responsiveness */

/* For devices with widths less than 768px (e.g., tablets, large phones) */
@media (max-width: 768px) {
  .labeled-input {
    max-width: 100%; // Allow the input to take full width
    margin-bottom: 1rem;

    &__label {
      font-size: 10px; // Adjust font size for smaller screens
    }

    &__input {
      font-size: 12px; // Adjust font size for smaller screens
      padding: 8px; // Reduce padding for better fit
    }

    &__error {
      font-size: 10px; // Smaller font size for error message
    }
    &__toggle-visibility {
      font-size: 10px;
    }
  }
}

/* For devices with widths less than 480px (e.g., small phones) */
@media (max-width: 480px) {
  .labeled-input {
    &__label {
      margin-bottom: 0.25rem; // Reduce spacing for compact layout
    }

    &__input {
      font-size: 10px; // Further adjust font size for very small screens
      padding: 6px; // Minimal padding
    }

    &__error {
      font-size: 10px;
    }
    &__toggle-visibility {
      font-size: 8px;
    }
  }
}
