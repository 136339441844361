// Variables
$footer-bg-color: #f0f0f0;
$footer-text-color: var(--color-text);
$footer-link-color: var(--color-btn-primary);
$footer-link-hover-color: var(--color-btn-primary-hover);

// Mixins for media queries
@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}

.footer {
  background-color: $footer-bg-color !important;
  width: 100% !important;
  overflow: hidden;
  z-index: 1000;
  left: 0px !important;
  height: auto !important; // Changed from fixed height for better responsiveness
  padding: 1rem !important; // Unified padding
  flex-shrink: 0;

  @include for-tablet-landscape-up {
    padding-left: 16rem !important;
    padding-right: 1rem !important;
  }

  .footer-content {
    display: flex;
    flex-direction: column; // Stack items vertically on smaller screens
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    font-size: 14px;
    color: $footer-text-color;

    @include for-tablet-portrait-up {
      flex-direction: row;
      justify-content: space-between;
    }

    .copyright {
      text-align: center;
      margin-bottom: 1rem; // Add space between copyright and links on small screens

      @include for-tablet-portrait-up {
        margin-bottom: 0;
      }
    }

    .footer-links {
      display: flex;
      justify-content: center;
      flex-wrap: wrap; // Allow links to wrap on very small screens
      align-items: center;
      text-align: center;

      @include for-tablet-portrait-up {
      }

      .footer-link {
        color: $footer-link-color;
        text-decoration: none;
        transition: color 0.3s ease;
        padding: 0.5rem; // Add padding for better touch targets

        &:hover {
          color: $footer-link-hover-color;
          text-decoration: underline;
        }

        @include for-tablet-portrait-up {
          padding: 0;
        }
      }
    }
  }
}
