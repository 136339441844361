.toggle-button {
  background-color: transparent;
  color: var(--color-primary-dark);
  border: none; /* Remove border from unselected buttons */
  border-radius: 20px;
  padding: 3px 12px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.6s ease;
}

.toggle-button.selected {
  color: var(--color-primary-light);
  border: 1px solid var(--color-border); /* Add border for selected button */
  font-weight: 500; /* Make the selected button's text bold */
}

.toggle-button:not(.selected):hover {
  color: var(--color-placeholder-text);
}
