@import "./colors";
@import "./mixins";
@import "../assets/scss/index.scss";

:root {
  --color-primary-dark: #003528;
  --color-primary-light: #137c4e;
  --color-secondary-light: #f8f9f8;
  --color-secondary-dark: #f2f3f0;
  --color-border-secondary: #8b9f9a;
  --color-placeholder-text: #53605d;
  --color-border: #dce0d9;
  --color-title: #004131;
  --color-green-100: #e3efe3;
  --color-border-primary: #c6c6c6;
  --color-background-primary: #f0f0f0;

  /* Button Colors */
  --color-btn-primary: #137c4e;
  --color-btn-primary-hover: #0f623b; /* Slightly darker for hover */
  --color-btn-primary-active: #0c4e2d; /* Even darker for active state */

  --color-btn-danger: #b61111;
  --color-btn-danger-hover: #920e0e; /* Slightly darker for hover */
  --color-btn-danger-active: #7a0c0c; /* Even darker for active state */

  --color-btn-info: #5186d4;
  --color-btn-info-hover: #5a8bd1; /* Slightly darker for hover */
  --color-btn-info-active: #4d7bb6; /* Even darker for active state */

  --color-btn-blue: #0a2463;
  --color-btn-blue-hover: #081a4a; /* Slightly darker for hover */
  --color-btn-blue-active: #061336; /* Even darker for active state */

  --color-pink: #f37c7c;
  --color-pink-light: #fff3f3;
  --color-yellow: #deb00b;
  --color-light-yellow: #fae000;
  --color-light-peach: #fff2da;
  --color-gray: #dce0d9;
  --color-blue: #0a2463;
  --color-light-blue: #5186d4;
  --color-green: #409c78;
  --color-orange: #ff823b;
  --color-red: #b61111;
  --color-red-light: #c9302c;
  --color-white: #ffffff;
  --color-light-gray: #f2f3f0;
  --color-light-purple: #e7eeff;
  --color-icon-bg-blue: #2b7dfa;
  --color-table-header: #f4f4f4;
  --color-slider-bg: #c7c9c9;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden; /* Hide overflow on the body */
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #495057;
}

#root,
.app {
  height: 100%;
  display: flex;
  flex-direction: column;
}

img {
  color: var(--color-title);
  font-size: 12px;
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.app-content {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 60px);
}

.scrollable-container {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.card-container,
.tab-content .section {
  padding: 1rem;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 10px 20px 0px rgba(58, 70, 84, 0.12);
}
.text-info {
  font-size: 13px !important;
}

.all-workflow-states {
  display: flex;
  padding-bottom: 20px;
  align-items: center;
}

button svg {
  margin-right: 12px;
}

.btn:active {
  position: relative;
  z-index: 1000;
  top: 2px;
  left: 2px;
}

.endcap {
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0 4px 4px 0 !important;
}

.workflowgap {
  flex-grow: 1;
}

.font-size-15 {
  font-size: 15px;
}

.color-primary-light {
  color: var(--color-primary-light) !important;
}

.color-primary-dark {
  color: var(--color-primary-dark);
}

.color-gray-light {
  color: var(--color-border) !important;
}

::selection {
  background-color: var(--color-primary-light);
  color: var(--color-white);
}

/* Set the caret (cursor) color globally */
input,
textarea,
[contenteditable="true"] {
  caret-color: var(--color-primary-light);
}

::-webkit-scrollbar-thumb {
  background-color: var(--color-primary-light);
  border-radius: 6px; /* Round the corners */
}

::-webkit-scrollbar-track {
  border-radius: 6px; /* Round the corners */
  background-color: #c4ded3;
}

::-webkit-scrollbar {
  width: 10px; /* Set the width of the scrollbar */
  height: 10px;
}

.trash-container {
  width: 40px;
  height: 35px;
  background-color: var(--color-btn-danger);
  border-radius: 5px;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  display: flex;
  position: relative;
  top: 6.5px;
  cursor: pointer;
}

.trash-container:hover {
  background-color: var(--color-btn-danger-hover);
}

// AG-GRIG
.ag-icon {
  color: #409c78 !important;
}

.ag-root-wrapper.ag-layout-normal {
  border-radius: 10px !important;
  border-color: var(--color-border) !important;
}

.ag-text-field-input {
  border-color: var(--color-border) !important;
}

.ag-row-hover {
  background-color: #c4ded3 !important;
}

/* Selected row */
.ag-theme-alpine .ag-row-selected,
.ag-theme-alpine .ag-row-selected::before,
.ag-theme-alpine .ag-row-selected::after {
  background-color: #c4ded3 !important;
}

/* Hover state */
.ag-theme-alpine .ag-row:hover,
.ag-theme-alpine .ag-row:hover::before,
.ag-theme-alpine .ag-row:hover::after {
  background-color: #c4ded3 !important;
}

/* Selected row being hovered */
.ag-theme-alpine .ag-row-selected:hover,
.ag-theme-alpine .ag-row-selected:hover::before,
.ag-theme-alpine .ag-row-selected:hover::after {
  background-color: #c4ded3 !important;
}

/* Active state (being clicked) */
.ag-theme-alpine .ag-row:active,
.ag-theme-alpine .ag-row:active::before,
.ag-theme-alpine .ag-row:active::after {
  background-color: #c4ded3 !important;
}

/* Override the default selected row style */
.ag-theme-alpine .ag-row-selected {
  background-color: #c4ded3 !important;
}

/* Ensure text color remains visible on the new background */
.ag-theme-alpine .ag-row-selected .ag-cell {
  color: #003528 !important;
}

.continue-button {
  width: 130px;
}

.text-sentence-capitalize:first-letter {
  text-transform: uppercase;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.actions-header-col .ag-header-cell-comp-wrapper .ag-cell-label-container .ag-header-cell-label {
  justify-content: center;
}

.ellipsis-text {
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Add ellipsis for overflowing text */
  flex-grow: 1; /* Allow it to expand and take available space */
  width: 95%;
}
