.vertical-menu {
  padding-top: 0;
  overflow-y: auto;

  .navbar-brand-box {
    width: $navbar-brand-box-width;
    margin-bottom: 24px;
    padding: 0 1.25rem;
    display: flex;
    text-align: center;
    background-color: $sidebar-bg;
    transition-duration: 0.4s;
    position: relative;
  }

  @media (max-width: 600px) {
    overflow-x: hidden;
  }
}

#sidebar-menu {
  padding: 0 0 30px 0;

  .has-arrow:after {
    transform: rotate(0deg);
    color: $sidebar-menu-collapse-color;
  }

  .mm-active {
    > .has-arrow {
      &:after {
        transform: rotate(90deg);
        color: rgba($sidebar-bg, 1);
      }
    }
  }

  .has-arrow {
    &:after {
      content: "\003E";
      margin-left: auto;
      font-family: "Poppins", sans-serif;
      display: block;
      float: right;
      transition: transform 0.2s;
      font-size: 18px;
    }
  }

  > ul {
    &:hover {
      & > li {
        &:not(&:hover) {
          > a.active,
          > a.mm-active {
            // background: none !important;
          }
        }

        &:hover {
          > a {
            background-color: $sidebar-menu-item-hover-text-color;

            &.active,
            &.mm-active {
              background-color: $header-bg;
            }
          }
        }
      }
    }

    > li {
      > a {
        padding: 0.5rem 22px 0.5rem 0.5rem !important;
        margin: 0 0 0 0.75rem;
        border-radius: 2rem 0 0 2rem;

        > span,
        p > span {
          white-space: nowrap;
          margin-left: 0.5rem;
        }

        &.active,
        &.mm-active {
          background-color: $header-bg;
          p span,
          p svg {
            color: $sidebar-menu-item-active-color;
          }
        }
      }
    }
  }

  ul {
    li {
      a {
        height: $sidebar-menu-item-height;
        display: flex;
        align-items: center;
        padding: 0.625rem 1.5rem;
        color: $sidebar-menu-item-color;
        position: relative;
        font-size: 0.79rem;
        transition: all 0.4s;

        i,
        svg {
          display: inline-block;
          min-width: 1.75rem;
          font-size: 1.25rem;
          line-height: 1.40625rem;
          vertical-align: middle;
          color: white;
          transition: all 0.4s;
        }
      }

      .badge {
        margin-top: 4px;
      }

      ul.sub-menu {
        li {
          a {
            padding: 0.6125rem 22px 0.6125rem 2.1rem;
            font-size: 15px;
            color: $sidebar-menu-sub-item-color;

            p {
              display: flex;
              align-items: center;

              span {
                white-space: nowrap;
                margin-left: 0.5rem;
                max-width: 140px;
                text-overflow: ellipsis;
                overflow-x: hidden;
              }
            }

            i,
            svg {
              color: $sidebar-menu-sub-item-color;
            }

            &:hover {
              color: white;

              i,
              svg {
                color: white;
              }

              .badge {
                color: #fff;
                background-color: #feffff47;
              }
            }

            &.active {
              i,
              svg {
                color: $sidebar-menu-item-active-text-color;
              }
            }
          }
        }
      }
    }
  }
}

#sidebar-menu {
  ul {
    li {
      a {
        p {
          margin-bottom: 0;
        }

        i,
        svg {
          font-size: 1rem;
          min-width: 1.5rem;
        }
      }

      .menu-label {
        display: flex;
        align-items: center;
        text-align: left;
        width: 80%;
      }

      .menu-label svg {
        color: #8b9f9a;
      }

      .menu-text {
        font-size: 15px;
        width: 80%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .badge {
        color: $sidebar-menu-sub-item-color;
        background-color: #feffff47;
      }

      ul.sub-menu {
        a {
          justify-content: space-between;
        }

        &.mm-collapse {
          display: block;
          transition: all ease-out 0.2s;
          overflow: hidden;
          background: none;

          &:not(.mm-show) {
            max-height: 0;
          }
        }
      }
    }
  }
}

.mm-active {
  color: $sidebar-menu-item-active-color !important;

  .active {
    color: $sidebar-menu-item-active-color !important;

    p span,
    p svg {
      color: $sidebar-menu-item-active-text-color;
    }

    .badge {
      color: $sidebar-menu-item-active-text-color !important;
      background-color: $sidebar-menu-item-active-badge-bg-color !important;
    }

    i {
      color: $sidebar-menu-item-active-color !important;
    }
  }

  > i {
    color: $sidebar-menu-item-active-color !important;
  }
}

.dropdown-item {
  cursor: pointer;
  font-size: 0.875rem;

  &:hover {
    // background-color: var(--color-primary-light);

    svg,
    span {
      color: var(--color-primary-dark) !important;
    }
  }
}

.btn-add {
  --bs-btn-color: #fff;
  --bs-btn-bg: #273e61;
  --bs-btn-border-color: #273e61;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #1f324d;
  --bs-btn-hover-border-color: #1f324d;
  --bs-btn-focus-shadow-rgb: 111, 132, 234;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #1f324d;
  --bs-btn-active-border-color: #1f324d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #273e61;
  --bs-btn-disabled-border-color: #273e61;
}

.btn-edit {
  --bs-btn-color: #fff;
  --bs-btn-bg: #009ca6;
  --bs-btn-border-color: #009ca6;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #027b83;
  --bs-btn-hover-border-color: #027b83;
  --bs-btn-focus-shadow-rgb: 111, 132, 234;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #027b83;
  --bs-btn-active-border-color: #027b83;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #94a9aa;
  --bs-btn-disabled-border-color: #94a9aa;
}

.btn-delete {
  --bs-btn-color: #fff;
  --bs-btn-bg: #66094e;
  --bs-btn-border-color: #66094e;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #550842;
  --bs-btn-hover-border-color: #550842;
  --bs-btn-focus-shadow-rgb: 246, 128, 128;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #550842;
  --bs-btn-active-border-color: #550842;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6d4f69;
  --bs-btn-disabled-border-color: #6d4f69;
}

.btn-cancel {
  --bs-btn-color: #fff;
  --bs-btn-bg: #66094e;
  --bs-btn-border-color: #66094e;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #550842;
  --bs-btn-hover-border-color: #550842;
  --bs-btn-focus-shadow-rgb: 246, 128, 128;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #550842;
  --bs-btn-active-border-color: #550842;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6d4f69;
  --bs-btn-disabled-border-color: #6d4f69;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

.username-ellipsis {
  max-width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
  vertical-align: middle;
  display: inline-block;
}
