.wrapper-component {
  flex-grow: 1;
  overflow-y: auto;
  padding: 28px;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-color: var(--color-white);
  transition: all 0.6s ease;

  .header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 5px 0px;
    flex-direction: row;
    margin-bottom: 2px;

    .heading {
      flex-grow: 1;
      font-family: "Oktah Neue", "Poppins", sans-serif;
      font-size: 18px;
      font-weight: 600;
      color: var(--color-title);
      text-align: left;
      vertical-align: middle;
    }

    .text-btn {
      background-color: transparent;
      border: none;
      color: inherit;
      cursor: pointer;
      text-decoration: none;
      transition:
        background-color 0.6s ease,
        color 0.6s ease;
      font-family: "Oktah Neue", "Poppins", sans-serif;
      font-size: 16px;
      font-weight: 500;
      color: var(--color-btn-primary);
      padding: 5px 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--color-btn-primary-active);
      color: var(--color-white);

      &:hover,
      &:active {
        background-color: var(--color-btn-primary-hover);
        color: var(--color-white);
      }
    }
  }
}

/* Large desktops (min-width: 1200px) */
@media (min-width: 1200px) {
  .wrapper-component {
    padding: 32px;
    box-shadow: 3px 3px 6px 3px rgba(0, 0, 0, 0.1);

    .header-section {
      .heading {
        font-size: 18px;
      }

      .text-btn {
        font-size: 18px;
        padding: 5px 15px;
      }
    }
  }
}

/* Medium screens like desktops and large tablets (min-width: 992px and max-width: 1199px) */
@media (min-width: 992px) and (max-width: 1199px) {
  .wrapper-component {
    padding: 28px;
    box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.1);

    .header-section {
      .heading {
        font-size: 18px;
      }

      .text-btn {
        font-size: 18px;
        padding: 5px 15px;
      }
    }
  }
}

/* Tablets (min-width: 768px and max-width: 991px) */
@media (min-width: 768px) and (max-width: 991px) {
  .wrapper-component {
    padding: 24px;
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.1);

    .header-section {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 10px;

      .heading {
        font-size: 16px;
        margin-bottom: 10px;
      }

      .text-btn {
        font-size: 16px;
        padding: 5px 15px;
      }
    }
  }
}

/* Small tablets and large phones (min-width: 576px and max-width: 767px) */
@media (min-width: 576px) and (max-width: 767px) {
  .wrapper-component {
    padding: 20px;
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);

    .header-section {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 8px;

      .heading {
        font-size: 15px;
        margin-bottom: 8px;
      }

      .text-btn {
        font-size: 15px;
        padding: 5px 15px;
      }
    }
  }
}

/* Mobile devices (max-width: 575px) */
@media (max-width: 575px) {
  .wrapper-component {
    padding: 15px;
    box-shadow: none;

    .header-section {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 5px;

      .heading {
        font-size: 14px;
        margin-bottom: 5px;
      }

      .text-btn {
        font-size: 14px;
        padding: 5px 12px;
      }
    }
  }
}
