.dashboard-card-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 2px solid var(--color-border);
  border-radius: 5px;
  padding: 18px 18px;
  .left-col {
    display: flex;
    flex-direction: column;
    gap: 7px;
    align-items: start;
    .title {
      font-size: 13px;
      color: var(--color-primary-dark);
    }
  }
  .count {
    font-size: 35px;
    font-weight: bold;
    color: var(--color-gray);
  }
}
