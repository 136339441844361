.form-group {
  margin-bottom: 10px;
  width: 100%;

  .form-label {
    margin-bottom: 5px;
    font-size: 14px;
    color: var(--color-primary-dark);
  }

  .switch {
    width: 30px;
    color: #53605d !important;
  }

  .form-check-input:checked {
    background-color: var(--color-primary-light);
    border-color: var(--color-primary-light);
  }

  .form-switch .form-check-input {
    width: 35px;
    height: 19px;
  }

  .input-group {
    position: relative;
    margin-bottom: 5px;
    .form-control {
      height: 35px;
      padding: 5px 8px;
      font-size: 14px;
      color: var(--color-placeholder-text);
      border: 1px solid var(--color-border);
      border-radius: 0.25rem !important;
      width: 100%;
      z-index: 1;

      &.--error {
        border: 1px solid var(--color-red) !important;
      }
      &.currency-input {
        padding-left: 32px;
      }

      &:focus {
        z-index: 1;
        outline: none;
      }
    }

    .currency-symbol {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      color: #137c4e;
      z-index: 2;
      font-size: 14px;
      pointer-events: none;
    }

    .currency-text {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      color: #53605d;
      font-size: 14px;
      z-index: 2;
      pointer-events: none;
    }

    .input-icon {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      pointer-events: none;
      z-index: 2;
    }
    .form-control-with-icon {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      color: var(--color-placeholder-text);
      border: 1px solid var(--color-border);
      height: 35px;
      width: 100%;
      padding: 0px 6px;
      border-radius: 5px;

      input {
        border: 0px;
      }
    }
  }

  .input-field {
    width: 100%;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .input-error-message {
    font-size: 12px;
    color: var(--color-red);
    margin-top: 5px;
  }
}
.sms-opt-in {
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 40px;
  width: 100%;

  @media (min-width: 576px) and (max-width: 1200px) {
    position: relative;
    top: 5px;
  }

  @media (max-width: 576px) {
  }

  input[type="checkbox"] {
    margin-right: 5px;
    appearance: none;
    -webkit-appearance: none;
    width: 16px;
    min-width: 16px;
    max-width: 16px;
    height: 16px;
    border: 1px solid var(--color-primary-light);
    border-radius: 3px;
    outline: none;
    cursor: pointer;
    position: relative;

    &:checked {
      background-color: var(--color-primary-light);
      border-color: var(--color-primary-light);

      &::after {
        content: "\2714";
        font-size: 10px;
        color: #ffff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .sms-opt-in-label {
    font-weight: 500;
    margin-bottom: 0px;
    font-size: 12px;
    color: var(--color-placeholder-text);
    width: 100%;
  }

  .sms-opt-in-text {
    font-size: 0.2rem;
    color: var(--color-primary-dark);
  }
}
