.tb-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 500px;
  height: auto;
  min-height: 160px;
  border-radius: 5px;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    opacity: 0.8;
  }

  &.primary {
    .left-bar {
      background-color: var(--color-primary-dark) !important;
    }

    .title {
      color: var(--color-primary-dark) !important;
    }

    .top-content {
      border-bottom: 2px solid var(--color-primary-dark) !important;
    }
  }

  &.info {
    .left-bar {
      background-color: var(--color-light-blue) !important;
    }

    .title {
      color: var(--color-light-blue) !important;
    }

    .top-content {
      border-bottom: 2px solid var(--color-light-blue) !important;
    }
  }

  &.success {
    .left-bar {
      background-color: var(--color-primary-light) !important;
    }

    .title {
      color: var(--color-primary-light) !important;
    }

    .top-content {
      border-bottom: 2px solid var(--color-primary-light) !important;
    }
  }

  &.warning {
    .left-bar {
      background-color: var(--color-yellow) !important;
    }

    .title {
      color: var(--color-yellow) !important;
    }

    .top-content {
      border-bottom: 2px solid var(--color-yellow) !important;
    }
  }

  &.danger {
    .left-bar {
      background-color: var(--color-red) !important;
    }

    .title {
      color: var(--color-red) !important;
    }

    .top-content {
      border-bottom: 2px solid var(--color-red) !important;
    }
  }

  .left-bar {
    width: 15px;
    min-height: 100%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .tb-content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .top-content {
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid var(--color-light-blue);
    padding: 10px;
    font-size: 13px;
    border-top-right-radius: 5px;
  }

  .middle-content {
    flex: 3;
    display: flex;
    border-bottom: 2px solid var(--color-border);
  }

  .middle-item {
    flex: 1;
    border-right: 2px solid var(--color-border);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 10px;

    .property-name {
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }

    .property-value {
      font-size: 12px;
      font-weight: 600;
      white-space: nowrap;
      color: var(--color-primary-light);
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }

    &.last {
      border-right: none;
    }
  }

  .bottom-content {
    padding: 10px;
    font-size: 13px;
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  // Media Queries
  @media screen and (max-width: 480px) {
    // Mobile
    flex-direction: row; // Keep it row to maintain left bar
    height: auto;

    .left-bar {
      width: 15px;
      min-height: 100%;
    }

    .middle-content {
      flex-direction: column;
    }

    .middle-item {
      border-right: none;
      border-bottom: 2px solid var(--color-border);

      &.last {
        border-bottom: none;
      }
    }
  }

  @media screen and (min-width: 481px) and (max-width: 768px) {
    // Tablet
    width: 100%;
    max-width: none;

    .middle-item {
      .property-name,
      .property-value {
        font-size: 11px;
      }
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    // Small Laptop
    width: 100%;
    max-width: none;

    .middle-item {
      .property-name,
      .property-value {
        font-size: 11px;
      }
    }
  }
  @media screen and (min-width: 1025px) and (max-width: 1366px) {
    // Small Laptop
    max-width: 480px;
    .middle-item {
      .property-name,
      .property-value {
        font-size: 11px;
      }
    }
  }

  @media screen and (min-width: 1367px) {
    // Larger Screens
    max-width: 500px;
  }
}
