// InfoCardComponent.scss

.info-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  text-align: center;
  padding: 20px;
  border-radius: 8px;
  background-color: var(--color-white);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--color-border);
  .icon-container {
    font-size: 2rem; // Larger icon size
    // color: #ffc107; // Warning color
    color: #555; // Muted text color
    margin-bottom: 0.5rem;
  }

  .message {
    font-size: 1rem;
    color: #555; // Muted text color
    font-weight: 500;
    margin: 0;
  }
}
