.theme-anerva {
  font-family: "Oktah Neue", "Poppins", sans-serif;

  .vertical-menu {
    background-color: #003528 !important;
    border: 0px solid #003528;
    transition: all 0.6s ease;
  }

  .main-content,
  .page-content {
    transition: all 0.6s ease;
  }

  .vertical-menu,
  #sidebar-menu {
    overflow-y: auto; /* Ensure the content can be scrolled */
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer 10+ */
  }

  #sidebar-menu::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }

  #sidebar-menu > ul > li > a {
    transition:
      background-color 0.6s ease,
      color 0.6s ease;
    background-color: #003528 !important;
  }

  #sidebar-menu > ul > li > a:hover,
  #sidebar-menu > ul > li > a.mm-active {
    background-color: #f8f9f8 !important;
    color: #137c4e !important;
  }

  #sidebar-menu ul li a i,
  #sidebar-menu ul li a svg {
    color: #f8f9f895 !important;
  }

  .mm-active {
    transition:
      background-color 0.6s ease,
      color 0.6s ease,
      border-left 0.6s ease;
  }

  #sidebar-menu > ul > li > a:hover div span,
  #sidebar-menu > ul > li > a.mm-active div span {
    color: #137c4e !important;
    font-weight: bold !important;
  }

  #sidebar-menu > ul > li > a:hover div svg,
  #sidebar-menu > ul > li > a.mm-active div svg {
    color: #137c4e !important;
    .hide-arrow {
      display: none !important;
    }
  }

  #sidebar-menu > ul > li > a:hover > svg,
  #sidebar-menu > ul > li > a.mm-active > svg {
    color: #137c4e !important;
  }

  #sidebar-menu > ul > li > a div svg {
    color: #8b9f9a !important;
  }

  #sidebar-menu > ul > li > a:hover > svg.custom-icon,
  #sidebar-menu > ul > li > a.mm-active > svg.custom-icon {
    display: inline-block !important;
    color: #137c4e !important;
  }

  .vertical-menu .navbar-brand-box {
    background-color: transparent !important;
    border-bottom: 1px solid #003528;
  }

  #sidebar-menu ul li ul.sub-menu li {
    background-color: transparent !important;
    border-bottom: 1px solid #003528;
    margin-left: 15px;
  }

  .sub-menu-bottom-border {
    display: none;
  }

  #sidebar-menu ul li ul.sub-menu li a.active svg,
  .mm-active .active p span {
    color: #137c4e !important;
  }

  #sidebar-menu ul li ul.sub-menu li a:hover svg {
    color: #137c4e !important;
  }

  #sidebar-menu ul li ul.sub-menu li a.active > div > svg {
    display: none !important;
  }

  #sidebar-menu > ul > li > a.active p span {
    color: white !important;
  }

  .sidebar-version-number {
    background-color: #003528 !important;
    width: 100% !important;
    padding: 10px 20px !important;
    bottom: 0px !important;
  }

  .navbar-header {
    background: #f8f9f8 !important;
    border-bottom: 1px solid var(--color-border) !important;
    transition: all 0.6s ease;
    button {
      color: #137c4e !important;
      .arrow-down {
        color: #137c4e;
      }
    }
    .header-notification {
      display: inline-block !important;
      padding: 0px 10px 0px 20px !important;
      &:hover {
        filter: brightness(1.1);
      }
      .notification-count {
        position: relative !important;
        margin-top: -15px !important;
        top: 3px !important;
        left: 15px !important;
        font-size: 10px !important;
        text-align: center !important;
        width: 18px !important;
        height: 18px !important;
        font-weight: 600 !important;
        background-color: #fae000 !important;
        border-radius: 10px !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        color: #137c4e !important;
      }
    }
    .language-dropdown {
      display: none !important;
    }
    .app-search {
      .form-control {
        border: 1px solid #dce0d9 !important;
        background: #fff !important;
        padding-left: 10px !important;
        padding-right: 40px !important;
        font-size: 14px !important;
        color: #53605d !important;
        font-family: "Poppins", sans-serif !important;
      }
      svg {
        left: unset !important;
        right: 10px;
        color: #137c4e !important;
      }
    }

    .custom-bell {
      color: #137c4e;
      font-size: 20px;
    }

    .setting-icons {
      display: none !important;
    }

    .profile-icons {
      justify-content: flex-end;
      flex: 1;
      margin-left: 1.5rem;
    }
  }

  .sidebar-link {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    text-decoration: none;
    border-radius: 2rem 0 0 2rem;
    color: #fff;
    border-left: 5px solid transparent;
    margin-left: 20px;
    transition:
      background-color 0.6s ease,
      color 0.6s ease,
      border-left 0.6s ease;

    &:hover,
    &.active {
      background-color: #004433;
      color: #ffffff;
      border-left: 5px solid #409c78;
      font-weight: bold !important;
      transition:
        background-color 0.6s ease,
        color 0.6s ease,
        border-left 0.6s ease;
    }

    .sidebar-link-content {
      display: flex;
      align-items: center;
      width: 100%;
    }

    .icon-wrapper {
      border-radius: 50%;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 35px;
      margin-right: 10px;
    }

    i {
      color: #8b9f9a;
      font-size: 18px;
      transition: color 0.6s ease;

      &:hover {
        color: #137c4e !important;
      }
    }

    .badge {
      color: #409c78 !important;
      transition: color 0.6s ease;

      &:hover,
      &:active {
        color: #409c78 !important;
      }
    }

    .link-label {
      font-size: 15px;
      flex-grow: 1;
      color: #f8f9f8;
      max-width: 80%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      transition: font-weight 0.6s ease;

      &:hover,
      &.active {
        font-weight: bold;
      }
    }
  }

  .chevron-icon {
    color: #8b9f9a !important;
  }

  .module-label {
    color: #8b9f9a !important;
  }
}

.company-title-header {
  color: var(--color-primary-dark);
  font-size: 16px;
  white-space: nowrap; /* Prevents text from wrapping */
  overflow: hidden; /* Hides overflowed text */
  text-overflow: ellipsis; /* Adds ellipsis (...) when text overflows */
  max-width: 200px; /* Adjust the width as needed */
  min-width: 200px;
  font-weight: 600;
}
