.form-select-dropdown {
  width: 90% !important;
  margin: auto;
  font-size: 16px !important;
}
.module-label {
  display: inline-block;
  color: #7e97ac;
  font-size: 13px;
  text-transform: uppercase;
  margin: 0.8rem 0 0.8rem 30px;
}

.sidebar-worldClock {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0px;
  width: 100%;
  .clock-title {
    display: none;
  }
  .wc-container {
    width: 100%;
    background-color: transparent;
  }
  .wc-container,
  .world-clock {
    background-color: transparent !important;
  }
  .world-clock-bottom {
    margin-top: 10px;
  }
}

#sidebar-menu {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.sidebar-version-number {
  position: absolute !important;
  bottom: 25px;
  left: 0px;
  padding-left: 15px;
  z-index: 10000;
  color: white;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.1;
}
