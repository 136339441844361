.np-container {
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 5px;
}

.np-header {
  text-align: center;
  padding: 1rem;
  background-color: var(--color-primary-light);
  color: white;
  font-size: 16px;
}

.np-body {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  .np-textarea-wrap {
    padding: 1rem;
    padding-bottom: 0px;
    display: flex;
  }
  .np-textarea {
    background-attachment: local;
    background-image: linear-gradient(to right, white 0px, transparent 0px),
      linear-gradient(to left, white 0px, transparent 0px),
      repeating-linear-gradient(white, white 30px, var(--color-primary-light) 15px, white 31px);
    line-height: 31px;
    resize: none;
    width: 100%;
    border: 0;
    outline: none;
    &::placeholder {
      color: var(--color-placeholder-text);
    }
  }
  .np-history {
    color: black;
    margin-left: 1rem;
    margin-right: 1rem;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .np-history::-webkit-scrollbar {
    display: none;
  }
}
