.dropdown {
  position: relative;

  .custom-dropdown-menu {
    position: absolute;
    top: 0%; // Position the dropdown below the button
    z-index: 1000;
    width: 200px;
    flex-direction: column;
    text-align: left;
    list-style: none;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
    left: -75px;

    .dropdown-item {
      display: block;
      width: 100%;
      clear: both;
      font-weight: 400;
      text-align: inherit;
      white-space: nowrap;
      background-color: transparent;
      border: 0;
      font-size: 13px;
      padding: 5px;

      &__share {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: var(--color-light-peach);
        padding: 10px;
        font-size: 14px;
        color: var(--color-orange);
      }

      &:hover {
        background-color: #f8f9fa;
        color: #16181b;
      }
    }

    .divider {
      height: 1px;
      width: 80%; // Decrease the width (adjust as needed)
      margin: 0.2rem auto; // Center the element horizontally
      overflow: hidden;
      background-color: var(--color-border);
    }

    .red-icon {
      color: var(--color-btn-danger);
    }

    .green-icon {
      color: var(--color-primary-light);
    }

    .icon-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 5px;
    }
  }

  &.open .custom-dropdown-menu {
    display: block; // Show the dropdown when the .open class is added
  }
}
