.overall-rating {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.rating-container {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.rating-label {
  font-size: 14px;
  color: #53605d;
  font-weight: 400;

  &.right {
    margin-right: 8px;
  }

  &.left {
    margin-left: 8px;
  }
}

.rating-button {
  width: 40px;
  height: 40px;
  margin: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dce0d9;
  border-radius: 50%;
  color: #137c4e;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  transition:
    background-color 0.8s,
    color 0.8s,
    border-color 0.8s,
    transform 0.8s;
}

.rating-button.selected {
  background-color: #137c4e;
  color: white;
  font-weight: 600;
}

.rating-button:hover {
  background-color: #137c4e;
  color: white;
}

.text-input-container {
  margin-top: 20px;
}

.label-rating {
  font-size: 14px;
  color: var(--color-primary-dark);
}

// Media queries for responsiveness
@media screen and (max-width: 1200px) {
  .rating-button {
    width: 30px;
    height: 30px;
    font-size: 14px;
    margin: 0 6px;
  }

  .rating-label {
    font-size: 13px;
  }
}

@media screen and (max-width: 768px) {
  .rating-button {
    width: 25px;
    height: 25px;
    font-size: 12px;
    margin: 0 5px;
  }

  .rating-label {
    font-size: 12px;
  }

  .label-rating {
    font-size: 13px;
  }

  .text-input-container {
    margin-top: 15px;
  }
}

@media screen and (max-width: 480px) {
  .rating-button {
    width: 20px;
    height: 20px;
    font-size: 11px;
    margin: 0 4px;
  }

  .rating-label {
    font-size: 11px;
  }

  .label-rating {
    font-size: 12px;
  }

  .text-input-container {
    margin-top: 10px;
  }
}
