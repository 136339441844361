.history-detail {
  padding: 3px 0px;
  margin-top: 12px;
  border-bottom: 1px solid var(--color-border);
  .history-createdBy {
    color: var(--color-primary-light);
    font-weight: 600;
    font-size: 16px;
  }
  .history-createAt {
    color: var(--color-placeholder-text);
    font-size: 12px;
  }
  .history-description {
    white-space: pre-wrap;
    overflow-wrap: break-word;
    margin-top: 5px;
    font-size: 14px;
  }
}
