.draggable-input-field {
  width: 100%;
  height: 40px;
  border: 1px solid var(--color-gray);
  margin-bottom: 20px;
  background-color: var(--color-white);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  .ellipsis-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 93%; // Ensure the text doesn't overflow its container
  }
}
