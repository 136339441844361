.breadcrumblink {
  color: #53605d;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 400;

  &.active {
    color: #137c4e;
    font-weight: 600;
  }
}

.chevron-right-icon {
  svg,
  .fa {
    color: rgba(19, 124, 78, 0.25);
  }
}
