// DeviceRegistrationModal.scss
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
  }
  to {
    transform: translateY(0);
  }
}

.device-registration-modal {
  h3 {
    color: #003528;
  }

  .modal-content {
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }

  .modal-header {
    background-color: #f8f9fa;
    border-bottom: 1px solid #e9ecef;
    .modal-title {
      color: #003528;
    }
    .close {
      font-size: 1.5rem;
    }
  }

  .modal-body {
    padding: 2rem;
  }

  .active-content,
  .timeout-content,
  .success-content {
    animation:
      fadeIn 0.3s ease-out,
      slideIn 0.3s ease-out;
  }

  .registration-code {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    .code {
      font-weight: 600;
      color: #0a2463;
    }
    .code-blank {
      color: #b61111;
    }
  }

  //   .time-remaining {
  //     font-size: 1.2rem;
  //     margin-bottom: 1rem;
  //     color: #003528;
  //     display: flex;
  //     align-items: center;
  //     .timer {
  //       font-weight: 600;
  //       color: #137c4e;
  //       margin-right: 0.5rem;
  //     }
  //     .fa-stopwatch {
  //       color: #137c4e;
  //     }
  //   }

  .time-remaining {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    color: #003528;
    display: flex;
    align-items: center;

    .timer-container {
      display: inline-flex;
      align-items: center;
      margin-left: 0.5rem;

      .timer {
        font-weight: 600;
        color: #137c4e;
        width: 4ch; // This sets a fixed width based on 4 characters (00:00)
        text-align: right;
        margin-right: 0.5rem;
      }

      .fa-stopwatch {
        color: #137c4e;
      }
    }
  }

  .instructions {
    font-size: 0.8rem;
    color: #003528;
  }

  .success-content {
    text-align: center;
    .success-icon {
      font-size: 3rem;
      color: #137c4e;
      margin-bottom: 1rem;
    }
    .success-message {
      font-size: 1.2rem;
      color: #137c4e;
    }
  }

  .expired-message {
    color: #b61111;
    font-weight: 600;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    .fa-circle-xmark {
      margin-right: 0.5rem;
    }
  }

  // Media queries
  @media (max-width: 1200px) {
    .modal-body {
      padding: 1.5rem;
    }
    .registration-code {
      font-size: 1.3rem;
    }
    .time-remaining {
      font-size: 1rem;
    }
  }

  @media (max-width: 992px) {
    .modal-body {
      padding: 1.2rem;
    }
    .registration-code {
      font-size: 1.2rem;
    }
    .time-remaining {
      font-size: 0.9rem;
    }
  }

  @media (max-width: 768px) {
    .modal-body {
      padding: 1rem;
    }
    .registration-code {
      font-size: 1.1rem;
    }
    .time-remaining {
      font-size: 0.8rem;
    }
    .instructions {
      font-size: 0.7rem;
    }
  }

  @media (max-width: 576px) {
    .modal-body {
      padding: 0.8rem;
    }
    .registration-code {
      font-size: 1rem;
    }
    .time-remaining {
      font-size: 0.75rem;
    }
    .instructions {
      font-size: 0.65rem;
    }
  }
}
