.switch-container {
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 5px 0px;
  .label {
    color: var(--color-primary-light);
    font-size: 15px;
    font-weight: 500;
    margin-right: 30px;
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 27px;
    top: 4px;
    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 23px;
    width: 23px;
    left: 2px;
    top: 2px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: var(--color-primary-light);
  }

  input:focus + .slider {
    box-shadow: 0 0 1px var(--color-primary-light);
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(22px);
    -ms-transform: translateX(22px);
    transform: translateX(22px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}
