.m-1 {
  margin: 10px !important;
}
.ml-1 {
  margin-left: 10px !important;
}
.mr-1 {
  margin-right: 10px !important;
}
.mt-1 {
  margin-top: 10px !important;
}
.mb-1 {
  margin-bottom: 10px !important;
}

@for $i from 1 through 100 {
  .font-size-#{$i} {
    font-size: #{$i}px !important;
  }
}

@for $i from 1 through 500 {
  .h-px-#{$i} {
    height: #{$i}px !important;
  }
}
@for $i from 1 through 500 {
  .w-px-#{$i} {
    width: #{$i}px !important;
  }
}

.w-48 {
  width: 48% !important;
}

@for $i from 1 through 100 {
  .width-#{$i} {
    width: #{$i + "%"} !important;
  }
}

.m-2 {
  margin: 20px !important;
}
.ml-2 {
  margin-left: 20px !important;
}
.mr-2 {
  margin-right: 20px !important;
}
.mt-2 {
  margin-top: 20px !important;
}
.mb-2 {
  margin-bottom: 20px !important;
}

.m-3 {
  margin: 30px !important;
}
.ml-3 {
  margin-left: 30px !important;
}
.mr-3 {
  margin-right: 30px !important;
}
.mt-3 {
  margin-top: 30px !important;
}
.mb-3 {
  margin-bottom: 30px !important;
}

.m-4 {
  margin: 40px !important;
}
.ml-4 {
  margin-left: 40px !important;
}
.mr-4 {
  margin-right: 40px !important;
}
.mt-4 {
  margin-top: 40px !important;
}
.mb-4 {
  margin-bottom: 40px !important;
}

.m-5 {
  margin: 50px !important;
}
.ml-5 {
  margin-left: 50px !important;
}
.mr-5 {
  margin-right: 50px !important;
}
.mt-5 {
  margin-top: 50px !important;
}
.mb-5 {
  margin-bottom: 50px !important;
}

.m-6 {
  margin: 60px !important;
}
.ml-6 {
  margin-left: 60px !important;
}
.mr-6 {
  margin-right: 60px !important;
}
.mt-6 {
  margin-top: 60px !important;
}
.mb-6 {
  margin-bottom: 60px !important;
}

.m-7 {
  margin: 70px !important;
}
.ml-7 {
  margin-left: 70px !important;
}
.mr-7 {
  margin-right: 70px !important;
}
.mt-7 {
  margin-top: 70px !important;
}
.mb-7 {
  margin-bottom: 70px !important;
}

.m-8 {
  margin: 80px !important;
}
.ml-8 {
  margin-left: 80px !important;
}
.mr-8 {
  margin-right: 80px !important;
}
.mt-8 {
  margin-top: 80px !important;
}
.mb-8 {
  margin-bottom: 80px !important;
}

.m-9 {
  margin: 90px !important;
}
.ml-9 {
  margin-left: 90px !important;
}
.mr-9 {
  margin-right: 90px !important;
}
.mt-9 {
  margin-top: 90px !important;
}
.mb-9 {
  margin-bottom: 90px !important;
}

.m-10 {
  margin: 100px !important;
}
.ml-10 {
  margin-left: 100px !important;
}
.mr-10 {
  margin-right: 100px !important;
}
.mt-10 {
  margin-top: 100px !important;
}
.mb-10 {
  margin-bottom: 100px !important;
}

.p-1 {
  padding: 10px !important;
}
.pl-1 {
  padding-left: 10px !important;
}
.pr-1 {
  padding-right: 10px !important;
}
.pt-1 {
  padding-top: 10px !important;
}
.pb-1 {
  padding-bottom: 10px !important;
}

.p-2 {
  padding: 20px !important;
}
.pl-2 {
  padding-left: 20px !important;
}
.pr-2 {
  padding-right: 20px !important;
}
.pt-2 {
  padding-top: 20px !important;
}
.pb-2 {
  padding-bottom: 20px !important;
}

.p-3 {
  padding: 30px !important;
}
.pl-3 {
  padding-left: 30px !important;
}
.pr-3 {
  padding-right: 30px !important;
}
.pt-3 {
  padding-top: 30px !important;
}
.pb-3 {
  padding-bottom: 30px !important;
}

.p-4 {
  padding: 40px !important;
}
.pl-4 {
  padding-left: 40px !important;
}
.pr-4 {
  padding-right: 40px !important;
}
.pt-4 {
  padding-top: 40px !important;
}
.pb-4 {
  padding-bottom: 40px !important;
}

.p-5 {
  padding: 50px !important;
}
.pl-5 {
  padding-left: 50px !important;
}
.pr-5 {
  padding-right: 50px !important;
}
.pt-5 {
  padding-top: 50px !important;
}
.pb-5 {
  padding-bottom: 50px !important;
}

.p-6 {
  padding: 60px !important;
}
.pl-6 {
  padding-left: 60px !important;
}
.pr-6 {
  padding-right: 60px !important;
}
.pt-6 {
  padding-top: 60px !important;
}
.pb-6 {
  padding-bottom: 60px !important;
}

.p-7 {
  padding: 70px !important;
}
.pl-7 {
  padding-left: 70px !important;
}
.pr-7 {
  padding-right: 70px !important;
}
.pt-7 {
  padding-top: 70px !important;
}
.pb-7 {
  padding-bottom: 70px !important;
}

.p-8 {
  padding: 80px !important;
}
.pl-8 {
  padding-left: 80px !important;
}
.pr-8 {
  padding-right: 80px !important;
}
.pt-8 {
  padding-top: 80px !important;
}
.pb-8 {
  padding-bottom: 80px !important;
}

.p-9 {
  padding: 90px !important;
}
.pl-9 {
  padding-left: 90px !important;
}
.pr-9 {
  padding-right: 90px !important;
}
.pt-9 {
  padding-top: 90px !important;
}
.pb-9 {
  padding-bottom: 90px !important;
}

.p-10 {
  padding: 100px !important;
}
.pl-10 {
  padding-left: 100px !important;
}
.pr-10 {
  padding-right: 100px !important;
}
.pt-10 {
  padding-top: 100px !important;
}
.pb-10 {
  padding-bottom: 100px !important;
}

.gap-1 {
  gap: 10px !important;
}
.gap-x-1 {
  column-gap: 10px !important;
}
.gap-y-1 {
  row-gap: 10px !important;
}

.gap-2 {
  gap: 20px !important;
}
.gap-x-2 {
  column-gap: 20px !important;
}
.gap-y-2 {
  row-gap: 20px !important;
}

.gap-3 {
  gap: 30px !important;
}
.gap-x-3 {
  column-gap: 30px !important;
}
.gap-y-3 {
  row-gap: 30px !important;
}

.gap-4 {
  gap: 40px !important;
}
.gap-x-4 {
  column-gap: 40px !important;
}
.gap-y-4 {
  row-gap: 40px !important;
}

.gap-5 {
  gap: 50px !important;
}
.gap-x-5 {
  column-gap: 50px !important;
}
.gap-y-5 {
  row-gap: 50px !important;
}

.gap-6 {
  gap: 60px !important;
}
.gap-x-6 {
  column-gap: 60px !important;
}
.gap-y-6 {
  row-gap: 60px !important;
}

.gap-7 {
  gap: 70px !important;
}
.gap-x-7 {
  column-gap: 70px !important;
}
.gap-y-7 {
  row-gap: 70px !important;
}

.gap-8 {
  gap: 80px !important;
}
.gap-x-8 {
  column-gap: 80px !important;
}
.gap-y-8 {
  row-gap: 80px !important;
}

.gap-9 {
  gap: 90px !important;
}
.gap-x-9 {
  column-gap: 90px !important;
}
.gap-y-9 {
  row-gap: 90px !important;
}

.gap-10 {
  gap: 100px !important;
}
.gap-x-10 {
  column-gap: 100px !important;
}
.gap-y-10 {
  row-gap: 100px !important;
}

.w-95 {
  width: 95% !important;
}

.w-90 {
  width: 90% !important;
}

.w-85 {
  width: 85% !important;
}

.w-80 {
  width: 80% !important;
}

.w-75 {
  width: 70% !important;
}
.w-72 {
  width: 72% !important;
}
.w-73 {
  width: 73% !important;
}

.w-70 {
  width: 70% !important;
}

.w-60 {
  width: 60% !important;
}

.w-48 {
  width: 48% !important;
}

.w-45 {
  width: 45% !important;
}

.w-40 {
  width: 40% !important;
}

.w-30 {
  width: 30% !important;
}

.w-20 {
  width: 20% !important;
}

.w-10 {
  width: 10% !important;
}
.w-15 {
  width: 15% !important;
}

.h-45 {
  height: 45% !important;
}

.h-40 {
  height: 40% !important;
}

.h-30 {
  height: 30% !important;
}

.h-20 {
  height: 20% !important;
}

.h-10 {
  height: 10% !important;
}
.h-15 {
  height: 15% !important;
}

.h-100px {
  height: 100px !important;
}

@media (max-width: 480px) {
  .sm-flex-wrap {
    flex-wrap: wrap !important;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.border-none {
  border: 0px !important;
}
