.form-group {
  margin-bottom: 10px;
  width: 100%;

  .form-label {
    margin-bottom: 5px;
    font-size: 14px;
    color: var(--color-primary-dark);
  }

  .date-field {
    position: relative;
    display: flex;
    width: 100%;

    .input-group {
      flex-grow: 1; /* Allow input group to take full width */
      display: flex;

      .form-control {
        height: 35px;
        padding: 5px 35px 5px 8px; // Adjust padding to accommodate icon
        font-size: 14px;
        color: var(--color-placeholder-text);
        border: 1px solid var(--color-border);
        width: 100%;
        box-sizing: border-box;
        border-radius: 4px;
        // z-index: 100;
      }
    }

    .calendar-icon {
      position: absolute;
      right: 0px;
      top: 40%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      // background-color: red;
      cursor: pointer;
      z-index: 1;
      padding-right: 10px;
      .fa-calendar {
        color: var(--color-primary-light);
        font-size: 15px;
        // z-index: 1001;
      }
    }
  }

  .invalid-feedback {
    font-size: 12px;
    color: red;
    margin-top: 3px;
  }
}

/* Change background color of selected day */
.flatpickr-day.selected {
  background-color: var(--color-primary-light) !important;
  border-color: var(--color-primary-light) !important;
}

/* Change background color of weekdays */
.flatpickr-weekday {
  background-color: var(--color-primary-light) !important;
}

span.flatpickr-weekday {
  background-color: var(--color-primary-light) !important;
}

/* Change background color of weekdays header and current month dropdown */
.flatpickr-weekdays,
.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-months .flatpickr-month,
.flatpickr-weekdays .flatpickr-weekdaycontainer {
  background-color: var(--color-primary-light) !important;
}
