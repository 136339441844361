.tab-buttons-container {
  width: 100%;
  overflow: hidden;
}

.tab-buttons-wrapper {
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */

  /* WebKit (Safari, Chrome) */
  &::-webkit-scrollbar {
    display: none;
  }
}

.tab-buttons {
  position: relative;
  display: inline-flex;
  align-items: center;
  border-bottom: 5px solid var(--color-white);
  margin: 20px 0;
}

.tab-buttons__button {
  background: none;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  position: relative;
  font-size: 16px;
  color: var(--color-primary-dark);
  transition: all 0.6s ease;
  white-space: nowrap;
}

.tab-buttons__button.active {
  color: var(--color-primary-light);
  font-weight: 600;
}

.tab-buttons__bar {
  position: absolute;
  bottom: -4px;
  left: 0;
  height: 7px;
  border-radius: 5px;
  background-color: rgba(0, 65, 49, 0.5);
  transition:
    width 0.6s ease,
    left 0.6s ease;
}

@media (max-width: 767px) {
  .tab-buttons {
    margin: 10px 0;
  }

  .tab-buttons__button {
    padding: 10px 10px;
    font-size: 14px;
  }

  .tab-buttons__bar {
    height: 5px;
  }
}
