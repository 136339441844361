.statistics-card {
  border-radius: 8px;
  padding: 16px;
  background-color: var(--color-white);
  border: 1px solid var(--color-border);
  display: flex;
  flex-direction: column;

  // if classNme is modal-statistics-card then don't add padding
  &.modal-statistics-card {
    padding: 0;
    border: none;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    .text {
      font-size: 14px;
      font-weight: bold;
      color: var(--color-primary-light);
    }

    .number {
      font-size: 14px;
      font-weight: bold;

      margin-left: 1ch; /* Space between percentages */

      .number-value {
        font-size: 14px;

        margin-left: 0.3ch; /* Space between percentages */

        &.color-0 {
          color: var(--color-green);
        }

        &.color-1 {
          color: var(--color-blue);
        }

        &.color-2 {
          color: var(--color-red);
        }
      }

      .number-value:last-child {
        margin-right: 0; /* Remove margin after the last number */
      }
    }
  }

  .body {
    font-size: 14px;
  }
}
