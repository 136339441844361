.button {
  color: var(--color-white);
  background-color: var(--color-btn-blue);
  padding: 5px 15px;
  border: 1px solid var(--color-border);
  border-radius: 5px;
  font-size: 17px;
  cursor: pointer;
  transition:
    background-color 0.6s,
    transform 0.1s;

  &:focus {
    border: 2px solid var(--color-btn-blue);
  }

  /* Hover effect for the button */
  &:hover:not(:disabled) {
    background-color: var(--color-btn-blue-hover); /* Define a darker hover color in your CSS variables */
  }

  /* Click (active) effect for the button */
  &:active:not(:disabled) {
    transform: translateY(2px); /* Pushes the button down slightly on click */
    background-color: var(--color-btn-blue-active); /* Define an even darker active color */
  }

  &.save-button {
    background-color: var(--color-btn-primary);

    &:hover:not(:disabled) {
      background-color: var(--color-btn-primary-hover); /* Define a darker hover color */
    }

    &:active:not(:disabled) {
      transform: translateY(2px);
      background-color: var(--color-btn-primary-active); /* Define an even darker active color */
    }

    &:disabled {
      background-color: #c4ded3;
      cursor: not-allowed;
    }
  }

  &.cancel-button {
    background-color: var(--color-btn-danger);

    &:hover:not(:disabled) {
      background-color: var(--color-btn-danger-hover); /* Define a darker hover color */
    }

    &:active:not(:disabled) {
      transform: translateY(2px);
      background-color: var(--color-btn-danger-active); /* Define an even darker active color */
    }

    &:disabled {
      background-color: #f1b0b7;
      cursor: not-allowed;
    }
  }

  &:disabled {
    opacity: 0.65;
    cursor: not-allowed;
  }
  &.bg-orange {
    background-color: var(--color-orange);
  }

  &.bg-green {
    background-color: var(--color-green);
  }

  /* Custom Text Colors */
  &.color-yellow {
    color: var(--color-yellow);
  }

  &.color-gray {
    color: var(--color-gray);
  }

  &.color-blue {
    color: var(--color-blue);
  }

  &.color-green {
    color: var(--color-green);
  }

  &.color-orange {
    color: var(--color-orange);
  }

  &.color-red {
    color: var(--color-red);
  }
}

@media (max-width: 767px) {
  .button {
    padding: 5px 10px;
    font-size: 15px;
  }
}
