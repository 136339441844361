.form-configurator {
  height: 100%;
  width: 100%;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-color: #f4f4f4;
  margin-left: 20px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 20px;
  z-index: 999;
  overflow-y: auto;
}
