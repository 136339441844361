.detailView-section {
  .card {
    padding: 20px;
    border-radius: 8px;
    background-color: var(--color-white);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid var(--color-border);
  }

  .detailView-container {
    display: flex;
    gap: 20px;
  }

  .detail-image-container {
    flex-shrink: 0;
    width: 130px;
    height: 130px;
    overflow: hidden; // Hide overflow
    border-radius: 10%;
    border: 1px solid var(--color-border);

    .detail-profile-image {
      width: 100%;
      height: 100%;
      object-fit: cover; // This will make the image cover the container
      object-position: center;
    }
  }

  .detailView-grid {
    flex-grow: 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;

    &.with-image {
      padding-top: 20px; // Adjust this value as needed
    }
  }

  .detailView-item {
    margin: 5px 0;
    font-size: 1em;

    strong {
      font-weight: bold;
      color: #555;
    }
  }
}

.customClass-info {
  border: 0px;
  box-shadow: none;
}
