.app-search {
  @media (max-width: 600px) {
    display: none;
  }

  svg {
    position: absolute;
    z-index: 10;
    font-size: 16px;
    line-height: 38px;
    left: 13px;
    top: 12px;
    color: var(--bs-secondary);
    cursor: pointer;
    &.fa-xmark {
      right: 9px;
      left: auto;
    }
  }
  ul {
    list-style-type: none;
    width: 90%;
    position: absolute;
  }
  .close-icon {
    right: 30px !important;
  }
}
